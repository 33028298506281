import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  InputBase,
  Stack,
} from "@mui/material";
import TeamBtns from "../Components/WebSite/TeamBtn";
import ClassBtn from "../Components/WebSite/ClassBtn";
import CustomBtn from "../Components/common/CustomBtn";
import Header from "../Components/common/Header";
import TextField from "@mui/material/TextField";

function WebSite() {
  const ssafyRegion = [
    ["서울", "A"],
    ["대전", "B"],
    ["광주", "C"],
    ["구미", "D"],
    ["부울경", "E"],
  ];
  const projectChapters = [
    ["공통", "i", 1],
    ["특화", "j", 2],
    ["자율", "k", 3],
  ];

  const [maxTeamNum, setMaxTeamNum] = useState(
    () => parseInt(localStorage.getItem("teamNum"), 10) || 5
  );
  const [selectedRegion, setSelectedRegion] = useState(
    JSON.parse(localStorage.getItem("region"))
  );
  const [selectedClass, setSelectedClass] = useState(
    !isNaN(localStorage.getItem("class"))
      ? Number(localStorage.getItem("class"))
      : null
  );

  const [password, setPassword] = useState(
    localStorage.getItem("password") || null
  );

  const [selectedChapter, setSelectedChapter] = useState(() => {
    try {
      JSON.parse(localStorage.getItem("region"));
    } catch (error) {
      setSelectedChapter(projectChapters[1]);
    }
  });

  const decreaseMaxUserCnt = () => {
    setMaxTeamNum((prev) => Math.max(prev - 1, 5));
  };

  const increaseMaxUserCnt = () => {
    setMaxTeamNum((prev) => Math.min(prev + 1, 12));
  };

  //반 선택 버튼
  const handleClassBtn = (index) => {
    setSelectedClass(index);
  };

  //공/특/자 선택
  const handleChapterChange = (event) => {
    const selected = projectChapters.find(
      ([chapterName]) => chapterName === event.target.value
    );
    setSelectedChapter(selected);
  };

  useEffect(() => {
    localStorage.setItem("chapter", JSON.stringify(selectedChapter));
  }, [selectedChapter]);

  //로컬 스토리지 저장
  useEffect(() => {
    localStorage.setItem("teamNum", maxTeamNum);
  }, [maxTeamNum]);

  useEffect(() => {
    localStorage.setItem("region", JSON.stringify(selectedRegion));
  }, [selectedRegion]);

  useEffect(() => {
    localStorage.setItem("class", selectedClass);
  }, [selectedClass]);

  useEffect(() => {
    localStorage.setItem("password", setPassword);
  }, [setPassword]);

  //모든 팀 한번에 열기
  const handleAllOpenBtn = () => {
    if (selectedRegion !== null && selectedClass !== null) {
      for (let i = 0; i < maxTeamNum; i++) {
        let teamCode = `${selectedRegion[1]}${(selectedClass + 1).toString()}${(
          i + 1
        )
          .toString()
          .padStart(2, "0")}`;
        window.open(
          `https://${selectedChapter[1]}11${teamCode}.p.ssafy.io`,
          "_blank"
        );
      }
    }
  };

  //모든 팀 게릿 오픈
  const handleAllGerritOpenBtn = () => {
    if (selectedRegion !== null && selectedClass !== null) {
      for (let i = 0; i < maxTeamNum; i++) {
        let teamCode = `${selectedRegion[1]}${(selectedClass + 1).toString()}${(
          i + 1
        )
          .toString()
          .padStart(2, "0")}`;
        window.open(
          `https://${selectedChapter[1]}11${teamCode}.p.ssafy.io:8989`,
          "_blank"
        );
      }
    }
  };

  //팀 선택 시 버튼
  const handleTeamBtn = (index) => {
    if (selectedRegion !== null && selectedClass !== null) {
      let teamCode = `${selectedRegion[1]}${(selectedClass + 1).toString()}${(
        index + 1
      )
        .toString()
        .padStart(2, "0")}`;
      window.open(
        `https://${selectedChapter[1]}11${teamCode}.p.ssafy.io`,
        "_blank"
      );
    }
  };

  //gerrit 선택시 버튼
  const handleGerrit = (index) => {
    if (selectedRegion !== null && selectedClass !== null) {
      let teamCode = `${selectedRegion[1]}${(selectedClass + 1).toString()}${(
        index + 1
      )
        .toString()
        .padStart(2, "0")}`;
      window.open(
        `https://${selectedChapter[1]}11${teamCode}.p.ssafy.io:8989`,
        "_blank"
      );
    }
  };

  //jira 선택시 버튼
  const handleJira = (index) => {
    if (selectedRegion !== null && selectedClass !== null) {
      let teamCode = `${selectedRegion[1]}${(selectedClass + 1).toString()}${(
        index + 1
      )
        .toString()
        .padStart(2, "0")}`;
      window.open(
        `https://ssafy.atlassian.net/projects/S11P${selectedChapter[2]}1${teamCode}`,
        "_blank"
      );
    }
  };

  //모든 팀 지라 한번에 열기
  const handleAllJiraOpenBtn = () => {
    if (selectedRegion !== null && selectedClass !== null) {
      for (let i = 0; i < maxTeamNum; i++) {
        let teamCode = `${selectedRegion[1]}${(selectedClass + 1).toString()}${(
          i + 1
        )
          .toString()
          .padStart(2, "0")}`;
        window.open(
          `https://ssafy.atlassian.net/projects/S11P${selectedChapter[2]}1${teamCode}`,
          "_blank"
        );
      }
    }
  };

  const handleGitLabOpenBtn = () => {
    window.open(
      `https://lab.ssafy.com/?name=S11P${selectedChapter[2]}1${
        selectedRegion[1]
      }${selectedClass + 1}&sort=name_asc`,
      "_blank"
    );
  };

  //지역 선택 조절
  const handleRegionChange = (event) => {
    const selected = ssafyRegion.find(
      ([regionName]) => regionName === event.target.value
    );
    setSelectedRegion(selected);
    setSelectedClass(null);
  };

  return (
    <>
      {password !== "11gi!" ? (
        <TextField
          label="비밀번호가 뭐게"
          color="error"
          focused
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              // 또는 event.keyCode === 13 사용 가능
              const value = event.target.value;
              const correctPassword = "11gi!";

              if (value === correctPassword) {
                setPassword(value);
                localStorage.setItem("password", value);
              } else {
                alert("비밀번호가 맞지 않습니다!"); // 상태를 이용해서 Alert를 표시해야 합니다.
              }
            }
          }}
          type="password"
        />
      ) : (
        <>
          <Header />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            {/* <ChapterRadioGroup
              selectedChapter={selectedChapter}
              handleChapterChange=
              {handleChapterChange}
            /> */}

            <RadioGroup
              row
              value={selectedChapter ? selectedChapter[0] : ""}
              onChange={handleChapterChange}
            >
              {projectChapters.map(([chapterName], index) => (
                <FormControlLabel
                  key={index}
                  value={chapterName}
                  control={<Radio />}
                  label={
                    <Typography
                      style={{ fontFamily: "Pretendard", fontSize: "14px" }}
                    >
                      {chapterName}
                    </Typography>
                  }
                  labelPlacement="bottom"
                />
              ))}
            </RadioGroup>

            {/* 지역 선택 */}
            {selectedChapter && (
              <RadioGroup
                row
                value={selectedRegion ? selectedRegion[0] : ""}
                onChange={handleRegionChange}
              >
                {ssafyRegion.map(([regionName], index) => (
                  <FormControlLabel
                    key={index}
                    value={regionName}
                    control={<Radio />}
                    label={
                      <Typography
                        style={{ fontFamily: "Pretendard", fontSize: "14px" }}
                      >
                        {regionName}
                      </Typography>
                    }
                    labelPlacement="bottom"
                  />
                ))}
              </RadioGroup>
            )}

            <Box display="flex" gap="0.5rem">
              {selectedRegion && selectedChapter && (
                <ClassBtn
                  selectedRegion={selectedRegion}
                  selectedClass={selectedClass}
                  handleClassBtn={handleClassBtn}
                />
              )}
            </Box>
          </Box>

          {selectedRegion && selectedChapter && selectedClass !== null && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={1}
              mb={10}
            >
              <Button
                onClick={decreaseMaxUserCnt}
                disabled={maxTeamNum === 5}
                color="error"
                variant="contained"
              >
                -
              </Button>

              <span
                style={{
                  marginLeft: "18px",
                }}
              >
                팀개수
              </span>
              <InputBase
                value={maxTeamNum}
                readOnly
                inputProps={{
                  min: 8,
                  max: 12,
                  style: {
                    textAlign: "center",
                    fontSize: "24px",
                    width: "48px",
                    color: "purple",
                  },
                }}
              />
              <Button
                onClick={increaseMaxUserCnt}
                disabled={maxTeamNum === 12}
                color="primary"
                variant="contained"
              >
                +
              </Button>
            </Box>
          )}

          <Box display="flex" gap="0.5rem">
            {selectedRegion && selectedChapter && selectedClass !== null && (
              <TeamBtns
                numButtons={maxTeamNum}
                selectedClass={selectedClass}
                selectedRegion={selectedRegion}
                handleTeamBtn={handleTeamBtn}
                handleGerrit={handleGerrit}
                handleJira={handleJira}
              />
            )}
          </Box>

          <Box mt={2}>
            {selectedRegion && selectedChapter && selectedClass !== null && (
              <Stack direction="row" spacing={2}>
                <CustomBtn
                  text={"모든 팀 한번에 열기 고?"}
                  btncolor={"purple"}
                  onClick={() => handleAllOpenBtn()}
                />

                <CustomBtn
                  text={"모든 팀 게릿 한번에 열기 고?"}
                  btncolor={"indigo"}
                  onClick={() => handleAllGerritOpenBtn()}
                />

                <CustomBtn
                  text={"모든 팀 지라 한번에 열기 고?"}
                  btncolor={"grey"}
                  onClick={() => handleAllJiraOpenBtn()}
                />

                <CustomBtn
                  text={"Gitlab 모아보기"}
                  btncolor={"cyan"}
                  onClick={() => handleGitLabOpenBtn()}
                />
              </Stack>
            )}
          </Box>
        </>
      )}
    </>
  );
}

export default WebSite;
