import * as React from "react";
import Grid from "@mui/material/Grid";
import styles from "./Header.module.css";

function Header() {
  const handleOpenAdmin = () => {
    window.open(`https://project.ssafy.com/admin/clss`, "_blank");
  };

  const handleOpenEdussafy = () => {
    window.open(`https://edu.ssafy.com`, "_blank");
  };

  const handleOpenGitLab = () => {
    window.open(`https://lab.ssafy.com`, "_blank");
  };
  return (
    <header className={styles["header"]}>
      <Grid container spacing={5}>
        <Grid item xs={4}>
          {/* <div className={styles["logo"]}>다들.. 잘하고 있는거 맞지..?</div> */}
        </Grid>
        <Grid item xs={8}>
          <div className={styles["menu"]}>
            <span className={styles["menuItem"]} onClick={handleOpenEdussafy}>
              Edussafy
            </span>
            <span className={styles["divisionLine"]} onClick={handleOpenAdmin}>
              |
            </span>
            <span className={styles["menuItem"]} onClick={handleOpenGitLab}>
              Gitlab
            </span>
            <span className={styles["divisionLine"]} onClick={handleOpenAdmin}>
              |
            </span>
            <span className={styles["menuItem"]} onClick={handleOpenAdmin}>
              관리자 페이지 바로가기
            </span>
          </div>
        </Grid>
      </Grid>
    </header>
  );
}

export default Header;
